<template>
  <router-view/>
</template>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title_box {
  text-align: center;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}
</style>
